import React, { useEffect, lazy } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import i18n from './i18n';
// import Menu from './components/Menu';
// import Footer from './components/Footer';
// import Home from './pages/Home';
// import Contact from './pages/Contact';
// import Blog from './pages/Blog';
// import BlogPost from './pages/BlogPost';
// import Error from './pages/Error';
// import Cookies from './pages/Cookies';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import { Helmet } from 'react-helmet';

export default function App() {
  const Menu = lazy(() => import('./components/Menu'));
  const Footer = lazy(() => import('./components/Footer'));
  const Home = lazy(() => import('./pages/Home'));
  const Contact = lazy(() => import('./pages/Contact'));
  const Blog = lazy(() => import('./pages/Blog'));
  const BlogPost = lazy(() => import('./pages/BlogPost'));
  const Error = lazy(() => import('./pages/Error'));
  const Cookies = lazy(() => import('./pages/Cookies'));

  const location = useLocation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    document.documentElement.lang = lng;
  }

  useEffect(() => {
    let lang = location.pathname.split('/')[1];

    if (i18n.options.supportedLngs.includes(lang) && lang !== i18n.language) {
      changeLanguage(lang);
    }
  }, [location])

  return (
    <>
      <Helmet htmlAttributes={{lang: i18n.language}}></Helmet>
      <Menu changeLanguage={changeLanguage} />
      <Switch>
        <Route exact path="/:lang(en|nl)?" component={Home} />
        {/* <Route path="/nl/over" component={About} />
        <Route path="/en/about" component={About} /> */}
        <Route path="/:lang(en|nl)?/cookies" component={Cookies} />
        <Route path="/:lang(en|nl)?/contact" component={Contact} />
        <Route path="/:lang(en|nl)?/blog/page/:page" component={Blog} />
        <Route path="/:lang(en|nl)?/blog/:category/page/:page" component={Blog} />
        <Route path="/:lang(en|nl)?/blog/:category/:slug" component={BlogPost} />
        <Route path="/:lang(en|nl)?/blog/:category" component={Blog} />
        <Route path="/:lang(en|nl)?/blog" component={Blog} />
        <Route path="*" component={Error} status={404} />
      </Switch>
      <Footer />
    </>
  );
}